.double-icon-button-container {
  height: auto;
  padding: 0px;
  overflow:hidden;

  button {
    padding: 0px;
  }

  :hover {
    background-color: #ffffff;
  }

  .double-icon-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .double-icon-left-container {
      display: flex;
      height: 100%;
      width: 100%;
      padding-left:4px;
      padding-right:4px;

      .button-text {
      }
      .left-icon {
        padding-top:2px;
      }
    }

    .double-icon-right-container {
      font-size: 22px;
      padding-left: 4px;
      padding-right:4px;
      padding-top:2px;
    }

    .double-icon-left-container:hover {
      background-color: #edebe9;
    }

    .double-icon-right-container:hover {
      background-color: #edebe9;
    }

    .hoverable:hover {
      background-color: #edebe9;
    }
  }
}